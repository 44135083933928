.fieldset-wrapper .description {padding-left: 0px; padding-bottom: 10px;}

.form-item .description {font-size: 15px; background-color: $describe_color; padding: 10px; margin-top: 10px; margin-bottom: 10px;}


div.vertical-tabs .form-type-textfield input {box-sizing: border-box; width: 100%}
// .field-name-field-antrag fieldset legend {display: none;}

fieldset legend {margin-bottom: 20px;font-size: 18px; color: #333; font-weight: bold;}
label.fel-field-label  {margin-bottom: 20px;font-size: 18px; color: #333;border-bottom: 1px solid #e5e5e5; display: block !important; font-weight: bold;}
label.fel-field-label.fel-field-label {font-size: 16px;margin-bottom: 0px;}


.field-type-entityreference.field-name-field-projekt-ziele-quantitativ legend span {font-size: 16px;margin-bottom: 0px;}
.field-type-entityreference.field-name-field-projekt-ziele-quantitativ .description.fel-field-help-text {font-size: 15px; background-color: $describe_color; padding: 10px; margin-top: 30px; margin-bottom: 10px;}
.field-type-entityreference.field-name-field-projekt-ziele-quantitativ #edit-field-projekt-ziele-quantitativ-und-form .fel-field-help-text.fel-field-help-text {margin-top: 10px;}



#edit-field-antrag-und-0-upload-button--2 {float: right; margin: 10px;}
.form-item-field-antrag-und-0 label {margin-bottom: 10px;}
.form-item-field-foerderzeitraum-und-0-value2 label {display: block;}

fieldset.date-combo .container-inline-date > .form-item {margin-bottom: 0px !important;}

.field-group-div h3 {margin-top: 0px;margin-bottom: 20px;}

.field-name-field-antragsteller legend,
.field-name-field-projektgebiet legend {display: none !important;}

.node-type-kernantrag , .page-node-add-kernantrag {
	.tabledrag-toggle-weight-wrapper {display: none;}
	.ief-entity-table thead {display: none;}
	td.ief-tabledrag-handle {display: none;}
	.field-multiple-table thead {display: none;}
	.field-multiple-drag {display: none;}
	.field-type-field-collection, .form-type-textfield , .form-type-textarea {margin-bottom: 20px !important;}
	.field .field-label {margin-bottom: 5px;}
	.field-collection-container {margin-bottom: 0px;}
	}

.field-name-field-location label {margin-bottom: 0px;}
.field-name-field-location .form-type-textfield.form-item-field-projektkontakt-und-form-field-location-und-0-locality {margin: 0px;}

h2.multipage-pane-title {margin: 0px; margin-left: 20px; margin-bottom: 20px;}

#field-collection-ajax-field-ziel-qualitativ-landing-pad .fel-field-label,
.field-name-field-antragsart  .fel-field-label {font-size: 16px;}

.multipage-button {float: right;}

.multipage-counter {margin-left: 20px; margin-top: 12px;margin-right: 20px;float: right;}

.kernantrag-node-form #edit-actions {float: right;}

.social-links.icon.fa {width: 40px;height: 40px;text-align: center;}

.field-name-field-ziel-qualitativ .form-submit {}

.field-name-field-ziel-qualitativ {padding-bottom: 0px;}
.field-name-field-projekt-ziele-quantitativ {clear: both;}

.field-widget-field-collection-ajax thead,
.field-widget-field-collection-ajax .tabledrag-toggle-weight-wrapper,
.field-widget-field-collection-ajax .field-multiple-drag {display: none;}

#field-collection-ajax-edit-item-form .form-actions {text-align: right;}
#edit-field-ziel-qualitativ-und-container .tabledrag-toggle-weight {display: none;}

#field-unterziel-qualitativ-values .field-label {display: none;}


.table-responsive {display: inline;}

#edit-field-ansprechpartner-und .description.fel-field-help-text {display: none;}

.ui-state-default {background-image: none;}

.field-widget-field-collection-ajax .container {border: none;}




.page-node .field.field-label-above {margin-bottom: 10px;margin-top: 20px;}


.field-name-field-projektkontakt {
	.field-name-field-location  {margin-bottom: 0px;margin-top: 0px;}
	
	
}
.field-name-field-methoden .select-or-other .fel-field-label {display: none !important;}


.field-name-ife-views { 
	div.title {font-weight: bold;}
	h4 {font-size: 16px;margin-bottom: 5px;}
	.item-list ul li {margin-bottom: -3px;}
	
	}
	
	.field-name-field-ziel-qualitativ {
		h3 {font-size: 18px;margin-bottom: 0px;}
		h4 {font-size: 16px;margin-bottom: 5px;}
		h4:before {content: "- ";}
		.field-name-field-ziel-massnahmen {padding-left: 20px;}
	}

.field-name-pdf-link-antrag {margin-top: 30px; border-top: 1px solid #eee; padding-top: 20px;}


.field-name-field-schwerpunkte input[type="checkbox"] {float: left;margin-top: 4px;}

.field-name-field-schwerpunkte label.option {text-indent: 0px;margin-left: 30px;display: block;}

.field-name-field-foerdergegenstand-2 input[type="checkbox"] {float: left;margin-top: 4px;}

.field-name-field-foerdergegenstand-2 label.option {text-indent: 0px;margin-left: 30px;display: block;}


.field-name-field-pg-programmgebiet label.fel-field-label,
.field-name-field-agreement-public label.fel-field-label  {border: none;}

.field-name-field-pg-programmgebiet input[type="checkbox"],
.field-name-field-agreement-public  input[type="checkbox"] {float: left;margin-right: 15px; margin-top: 3px;margin-bottom: 0px;}

input[type="radio"], input[type="checkbox"] {margin-top: 0px; margin-right: 10px;margin-bottom: 4px;}

.field-name-field-merkmale-pg .field-collection-item-field-merkmale-pg {margin-top: 20px; margin-bottom: 20px;}

.field-name-field-pg-merkmal {font-weight: bold;border-bottom: 1px solid grey;margin-bottom: 5px;}

select#edit-field-handlungsfelder-tid-selective.form-control.form-select {max-width: 400px;}
