.field-name-submitted-by {margin-bottom: 20px;}

span.file {overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  display: block;}
  
.field-name-field-dateianhang { margin-top: 20px;}



.well-body .menu li.active-trail a {background-color: #fff;}

h1.title {font-size: 42px;}

.addtoany_list {margin-top: 1px;display: block; text-align: center;}