// Breadcrumbs
// -----------------------------------------------------------------------------
$navline-bg:               none !default;
$navline-color:            #ccc !default;
$navline-active-color:     $gray-light !default;
$navline-separator:        '/' !default;


$navline-padding-vertical:   0px !default;
$navline-padding-horizontal: 0px !default;


.navline {
  padding: $navline-padding-vertical $navline-padding-horizontal;
  margin-bottom: 5px;
  list-style: none;
  background-color: $navline-bg;
  border-radius: $border-radius-base;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: ""; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $navline-color;
    }
  }

  > .active {
    color: $navline-active-color;
  }
}

.navline_footer {
  padding: $navline-padding-vertical $navline-padding-horizontal;
  margin-bottom: $line-height-computed;
  list-style: none;
  background-color: $navline-bg;
  border-radius: $border-radius-base;

  > li {
    display: inline-block;

    + li:before {
      // [converter] Workaround for https://github.com/sass/libsass/issues/1115
      $nbsp: "\00a0";
      content: "#{$navline-separator}#{$nbsp}"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: $navline-color;
    }
  }

  > .active {
    color: $navline-active-color;
  }
}

