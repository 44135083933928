.cookie-note {
	transition: opacity 0.5s ease-in-out;
	font-family: Arial, Helvetica, sans-serif;
	padding: 1.5em;
	position: fixed;
	opacity: 1;
	z-index: 999999;

	&.hidden {
		opacity: 0;
		visibility: hidden;
		display: block !important;
	}

	a {
		text-decoration: underline;
	}

	.cookie-note__text {
		font-size: 16px;
		line-height: 1.4em;
		margin: 0;
		text-align: left;
	}

	.cookie-note__button {
		transition: all 0.2s ease-in-out;
		border: 2px solid;
		border-color: transparent;
		float: right;
		cursor: pointer;
		padding: 0.5em 2.5em;
		text-decoration: none;
		margin-left: 2em;
	}

	&.cookie-note--bottom {
		bottom: 0;
		left: 0;
		width: 100%;
	}

	&.cookie-note--middle {
		width: 50%;
		left: 25%;
		top: 33%;
		border: 2px solid;
		max-width: 650px;

		h3 {
			margin-bottom: 1em;
			margin-top: 0;
		}

		.cookie-note__text {
			margin-bottom: 1em;
		}

		&.cookie-note--dark {
			border-color: #fff;

			h3 {
				color: #fff;
			}
		}

		&.cookie-note--light {
			h3 {
				color: #333;
			}
		}
	}

	&.cookie-note--top {
		top: 0;
		left: 0;
		width: 100%;
	}

	&.cookie-note--dark {
		background: rgba(0, 0, 0, 0.8);
		color: #fff;

		a {
			color: #fff;
		}

		.cookie-note__button {
			background: #fff;
			color: #333;

			&:hover {
				background: #333;
				border-color: #fff;
				color: #fff;
			}
		}
	}

	&.cookie-note--light {
		background: rgba(255, 255, 255, 0.9);
		border-color: #333;
		color: #333;

		a {
			color: #333;
		}

		.cookie-note__button {
			background: #333;
			color: #fff;

			&:hover {
				background: #fff;
				border-color: #333;
				color: #333;
			}
		}
	}

	}