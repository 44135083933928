// Page Layout
// -----------------------------------------------------------------------------

th.views-field.views-field-field-foerderbetrag.views-align-right {width: 135px;}
th.views-field-field-voting {width: 42px;}
th.views-field.views-field-ops {width: 110px;}

th.views-field-field-bewertung {width: 170px;}

.role-jury th.views-field.views-field-ops,
.role-extern th.views-field.views-field-ops {display: none;}

.views-exposed-form .views-exposed-widget .form-submit {margin: 0px;}

.page-projekte {
	h2 {margin-bottom: 5px;}
	h3 {margin: 0px; font-size: 18px;text-transform: uppercase;line-height: 1.2em;}
	h4 {font-size: 16px;}
	
	.view-filters {margin-bottom: 30px;}
	
	.pane-projektliste-mit-karte {margin-top: 30px;margin-bottom: 20px;border-bottom: 1px solid #ccc;padding-bottom: 20px;}
	
}

.node-type-kernantrag.projekt {
	.field-name-field-projekttitel {font-size: 28px;margin-bottom: 5px;font-weight: bold;text-transform: uppercase;line-height: 1.2em;}
	.field-name-field-projekt-slogan {margin: 0px;font-size: 21px;text-transform: uppercase;line-height: 1.2em;}
	
	.pane-addtoany-addtoany-button .addtoany_list {text-align: right;margin-bottom: 20px;margin-top: 20px;}
	
	.slick-wrapper {margin-bottom: 20px;}
	
	.subheaderline {margin-top: 30px; margin-bottom: 30px;}
	.field-name-field-location {margin-bottom: 20px; clear: both;}
	.name-block {margin-bottom: 1px; font-weight: bold;}
	
	.slick--skin--default .slick__slide {margin:5px;}
}

.filter-help {display: none;}