.crm-container .crm-section .label {float: none;}

.crm-container fieldset legend {width: 100%;}

#profilewrap1 fieldset legend {display: none; }
#profilewrap1 fieldset label {font-weight: bold;}

.crm-container .crm-section .label {float: left; font-size: 14px; font-weight: 700;color: #333; width:auto; padding: 0px;margin-bottom: 7px;}

.crm-container .crm-section .content {clear: both; margin-left: 0px;}

.crm-container input.crm-form-text, .crm-container .crm-icon-picker-button, .crm-container input.dateplugin {
    display: block;
    width: 100%;
    height: 34px;
    padding: 0px 12px !important;
    font-size: 14px;
    line-height: 1.42857;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;}
    
    .crm-container input.from-control { padding: 6px 12px;}

.crm-container .big {
    width: 100%;
}

